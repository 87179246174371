import axios from 'axios';

console.log('NODE_ENV', process.env.NODE_ENV);

const api = axios.create({
    ...(process.env.NODE_ENV === 'production' ?
        {baseURL: `${location.protocol}//${location.hostname}:${location.port}/`} :
        {baseURL: `${location.protocol}//${location.hostname}:5000/`}),
});



api.interceptors.response.use(response => response.data, error => {
    console.error(error);
});


function setAuth (name, pass) {
    api.defaults.headers.username = name;
    api.defaults.headers.password = pass;
}

export {api, setAuth};
