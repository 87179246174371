<template>
  <div>
    <v-card color="darkblueshade" class="px-10 py-6">
      <h2>Ergebnisse einsehen:</h2>
      <p style="margin-bottom: -6px">
        Diese Informationen werden benötigt um ihnen persönlich Feedback und
        weiter Hilfen anzubieten.
      </p>
      <p>Im Anschluss können Sie ihre Auswertung ansehen.</p>

      <!--
        TODO:
        + 
      -->

      <v-form ref="userform" v-model="valid">

        <v-container>
          <v-layout class="my-4" row wrap>
            <!--     Firmendaten Sektion     -->
            <v-flex sm12 xs12 md12 lg6>
              <p>Firmendaten:</p>
              <v-row class="pt-2 pb-0">
                <v-col cols="11" class="ml-4 py-0 mb-n4">
                  <v-text-field 
                    v-model="company" 
                    dark
                    single-line
                    md12
                  >
                    <template v-slot:label>
                      <p>Firmenname</p>
                    </template>
                    <v-text-field-placeholder
                      color="darkblueshade"
                    ></v-text-field-placeholder>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <!--     Mitarbeiterzahl  -->
              <v-row class="pt-2 pb-0">
                <v-col cols="11" class="ml-4 py-0 mb-n4">
                  <v-select
                    v-model="companySize"
                    dark
                    :rules="companySizeRules"
                    :items="companySizeOptions"
                    required
                    single-line
                  >
                    <template v-slot:label>
                      <p>
                        Mitarbeiterzahl*
                      </p>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <!--     Orientierung  -->
              <v-row class="pt-2 pb-0">
                <v-col cols="11" class="ml-4 pt-0 mb-1">
                  <v-select 
                    v-model="companyIndustry"
                    dark
                    :items="companyIndustryOptions"
                    :rules="companyIndustryRules"
                    required
                    single-line
                  >
                    <template v-slot:label>
                      <p>Orientierung/Betriebsfeld*</p>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-flex>
            <!--     Personendaten Sektion     -->
            <!--     Vorname Nachname  -->
            <v-flex sm12 xs12 md12 lg6>
              <p>Personendaten:</p>
              <v-row class="pt-2 pb-0">
                <v-col cols="5" class="ml-4 py-0 mb-n4">
                  <v-text-field 
                    v-model="name" 
                    dark 
                    single-line
                  >
                    <template v-slot:label>
                      <p>Vorname</p>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-spacer></v-spacer>
                </v-col>
                <v-col cols="5" class="py-0 mb-n6">
                  <v-text-field 
                    v-model="lastname" 
                    dark
                    single-line
                  >
                    <template v-slot:label>
                      <p>Nachname</p>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <!--      Email -->
              <v-row class="pt-2 pb-0">
                <v-col cols="11" class="ml-4 py-0 mb-n4">
                  <v-text-field
                  v-model="email"
                  dark
                  :rules="emailRules"
                  required
                  single-line
                  >
                  <template v-slot:label>
                      <p>Email*</p>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <!--     Email bestätigen  -->
              <v-row class="pt-2 pb-0">
                <v-col cols="11" class="ml-4 py-0 mb-n4">
                  <v-text-field 
                    v-model="emailRepeat" 
                    :rules=[emailRepeatRules.compareEmail(this.email)]
                    dark
                    single-line
                    required
                  >
                    <template v-slot:label>
                      <p>Email bestätigen*</p>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-flex>
            <!--      Ende der Sektion       -->
          </v-layout>
        </v-container>
        <p>*Angaben sind erforderlich</p>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import {getCompanyIndustryOptions, getCompanySizeOptions} from "@/VarHandler";

export default {
  name: "UserInfos",
  components: {},
  // props:{
  //   companySizeOptions: Array,
  //   companyIndustryOptions: Array
  // },
  data: () => ({
    valid: false,
    company: "",
    // companyRules: [(v) => !!v || "Firma wird benötigt"],
    companySize: "",
    companySizeOptions: [],
    companySizeRules: [(v) => !!v || "Mitarbeiterzahl wird benötigt"],
    companyIndustry: "",
    companyIndustryOptions: [],
    companyIndustryRules: [(v) => !!v || "Betriebsfeld wird benötigt"],
    name: "",
    nameRules: [(v) => !!v || "Vorname wird benötigt"],
    lastname: "",
    lastnameRules: [(v) => !!v || "Nachname wird benötigt"],
    email: "",
    emailRules: [
      (v) => !!v || "E-Mail wird benötigt", 
      (v) => /.+@.+\..+/g.test(v) || "E-Mail muss gültig sein",
      (v) => v.length <= 256 || "E-Mail ist zu lang",
    ],
    emailRepeat: "",
    emailRepeatRules: {
      compareEmail(mail){
        return v => v === mail || "E-Mails müssen identisch sein";
      }
    },
  }),
  watch: {
    valid(newVal){
      if(this.emailRepeat != ""){
        if (newVal && this.email === this.emailRepeat){
          this.$emit('valid-updated', true);
        }else if(!newVal){
          this.$emit('valid-updated', false); 
        }
      }
    },
    name(){
      this.emitToParent();
    },
    lastname(){
      this.emitToParent();
    },
    email(newValue){
      if(this.emailRepeat.length > 2){
        if(this.companySize !== "" && this.companyIndustry !== "" && /.+@.+\..+/g.test(newValue)){
          if(newValue === this.emailRepeat){
            this.$emit('valid-updated', true);
          }else{
            this.$emit('valid-updated', false);
          }
        }
        this.$refs.userform.validate();
      }
      this.emitToParent();
    },
    company(){
      this.emitToParent();
    },
    companyIndustry(){
      this.emitToParent();
    },
    companySize(){
      this.emitToParent();
    },
    emailRepeat(newValue, oldValue){
      if(oldValue === "" && newValue.length > 2){
        if(this.companyIndustry !== "" && this.companySize !== "" && /.+@.+\..+/g.test(this.email) && this.emailRepeat === this.email){
          this.$emit('valid-updated', true);
        }
      }else if(newValue === "" || newValue !== this.mail){
        this.$emit('valid-updated', false);
      }
    }
  },
  methods: {
    saveUserInfoInBrowserStorage(userInfo){
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    loadUserInfoFromBrowserStorage(){
      const data = JSON.parse(localStorage.getItem("userInfo"));
      if (data){
        this.name = data.firstName
        this.lastname = data.lastName
        this.email = data.email
        this.company = data.companyName
        let toCheck = this.getCompanySizeString(data.companySize.min, data.companySize.max);
        this.companySize = (this.companySizeOptions.includes(toCheck)) ? toCheck : "";
        toCheck = data.companyIndustry
        this.companyIndustry = (this.companyIndustryOptions.includes(toCheck)) ? toCheck : "";
      }
    },
    emitToParent(){
      let userInfo = {
        firstName: this.name,
        lastName: this.lastname,
        email: this.email,
        companyName: this.company,
        companySize: this.getCompanyMinMax(),
        companyIndustry: this.companyIndustry,
      }
      this.saveUserInfoInBrowserStorage(userInfo)
      this.$emit('info-updated', userInfo);
    },
    getCompanyMinMax(){
      if(this.companySize.charAt(0) == '<'){
        return {min: null, max: parseInt(this.companySize.substring(1))};
      }else if(this.companySize.charAt(0) == '>'){
        return {min: parseInt(this.companySize.substring(1)), max: null}; //Number statt parseInt
      }else{
        const numbers = this.companySize.split("-");
        return {min: parseInt(numbers[0]), max: parseInt(numbers[1])};
      }
    },
    getCompanySizeString(min, max){
      if(min === null){
        return ("<" + max);
      }else if(max === null){
        return (">" + min);
      }else{
        return ("" + min + "-" + max); 
      }
    },
    buildCompanySizeOptions(sizeRanges){
      for(let i = 0; i<sizeRanges.length; i++){
        this.companySizeOptions.push(this.getCompanySizeString(sizeRanges[i].min, sizeRanges[i].max));
      }
    },
  },
  mounted(){
    this.buildCompanySizeOptions(getCompanySizeOptions());
    this.companyIndustryOptions = getCompanyIndustryOptions();
    this.loadUserInfoFromBrowserStorage();
  }
};
</script>

<style scoped>
h2 {
  color: #eefbfb;
  font-size: 20pt;
  font-weight: bold;
}
p {
  color: #acacac;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;
}
.v-text-field >>> .v-input__slot::before {
  border-color: #acacac !important;
}
.v-autocomplete >>> .theme--dark.v-icon{
  color: #acacac;
}
</style>
