<template>
  <v-row class="mb-3 mt-3 ml-0 mr-0">
    <v-col v-if="!breaking" cols="1" class="mr-2 pa-5">
      <v-icon>mdi-circle-outline</v-icon>
    </v-col>
    <v-col :cols="breaking ? 12 : 8" class="pa-0 mr-5">
      <v-text-field
          v-model="textInput"
          :label="`Option ${index + 1}`"
          :rules="rules.required"
      ></v-text-field>
    </v-col>
    <v-col :cols="breaking ? 11 : 1" class="pa-0">
      <v-text-field
          v-model="valueInput"
          label="Wert"
          type="number"
          :rules="rules.number"
          outlined
      ></v-text-field>
    </v-col>
    <v-col cols="1" class="pa-5">
      <v-icon class="delete-icon" @click="deleteOption()">mdi-delete</v-icon>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Option",
  data: () => ({
    rules: {
      required: [val => (val || '').length > 0 || 'Muss gefüllt werden!'],
      number: [val => (val && !isNaN(val) && Number(val) > 0) || 'Ungültige Zahl']
    },
  }),
  props: {
    text: String,
    value: Number,
    index: Number,
  },
  computed: {
    breaking() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'lg';
    },
    textInput: {
      get() {
        return this.text;
      },
      set(value) {
        this.$emit('text', value, this.index);
        return value;
      }
    },
    valueInput: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('value', value, this.index);
        return value;
      }
    }
  },
  methods: {
    deleteOption() {
      this.$emit('close', this.index);
    }
  }
}
</script>

<style scoped>
  .delete-icon:hover {
    color: red;
  }
</style>