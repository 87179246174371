import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height container--fluid text-center"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCard,{staticClass:"pa-10"},[_c(VRow,[_c(VSpacer),_c(VIcon,[_vm._v("mdi-clipboard-edit-outline")])],1),_c('h2',{staticClass:"mb-5",staticStyle:{"color":"black"}},[_vm._v("Admin Verifikation")]),_c(VTextField,{staticClass:"ml-2 mr-4",attrs:{"label":"Name","prepend-icon":"mdi-account"},model:{value:(_vm.adminName),callback:function ($$v) {_vm.adminName=$$v},expression:"adminName"}}),_c(VTextField,{staticClass:"ml-2 mr-4",attrs:{"type":"password","label":"Passwort","prepend-icon":"mdi-key-variant"},model:{value:(_vm.adminPass),callback:function ($$v) {_vm.adminPass=$$v},expression:"adminPass"}}),_c(VBtn,{staticClass:"mt-2 mb-4 mr-10",attrs:{"outlined":""},on:{"click":function($event){return _vm.sendAuth()}}},[_vm._v("Senden")]),_c(VCardText,{staticStyle:{"text-align":"left"}},[_vm._v(" Der Nutzername und das Passwort wird benötigt um als verifizierter Admin Zugang zu der Editieroberfläche zu erhalten. ")])],1)],1),_c(VSnackbar,{attrs:{"timeout":"4000"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticStyle:{"color":"#FF5252"}},[_vm._v("Ungültige Anmeldedaten!")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }