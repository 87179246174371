<template>
  <div>
    <v-row v-if="loadedStartData" class="ma-0">
      <v-col :cols=" breaking ? 12 : 3">
        <TopicsTree
            @selected="setSelected"
            @cur-category-question-indices="setCurCategoryQuestionIndices"
            :tree="treeData"
            @tree-change="setAdminTree"
        ></TopicsTree>
      </v-col>
      <v-col class="text-center" :cols="breaking ? 12 : 7" v-if="selected === 0"> Zum Editieren klicken sie links auf ein Handlungsfeld oder eine Frage. </v-col>
      <v-col :cols="breaking ? 12 : 7" v-else-if="selected === 1">
        <ThemeEdit
            :cur-category-question-indices="curCategoryQuestionIndices"
            @tree-change="setAdminTree"
        ></ThemeEdit>
      </v-col>
      <v-col :cols="breaking ? 12 : 7" v-else-if="selected === 2">
        <QuestionEdit
            :cur-category-question-indices="curCategoryQuestionIndices"
        ></QuestionEdit>
      </v-col>
      <v-col :cols="breaking ? 12 : 2">
        <SaveEditSection
            @tree-change="setAdminTree"
        ></SaveEditSection>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {initAdminPage, getAdminTree, setAdminTree} from "@/VarHandler";
import ThemeEdit from "@/components/admin/ThemeEdit";
import QuestionEdit from "@/components/admin/QuestionEdit";
import TopicsTree from "@/components/admin/TopicsTree";
import SaveEditSection from "@/components/admin/SaveEditSection";

export default {
  name: "QuestionsEditor",
  components: {
    SaveEditSection,
    TopicsTree,
    QuestionEdit,
    ThemeEdit,
  },
  data: () => ({
    loadedStartData: false,
    selected: 1,
    curCategoryQuestionIndices: {},
    treeData: null,
  }),
  computed: {
    breaking() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
    },
  },
  async mounted() {
    await initAdminPage().then(() => {
      this.loadedStartData = true;
      this.treeData = getAdminTree();
    });
  },
  methods: {
    setSelected(s) {
      this.selected = s;
    },
    setCurCategoryQuestionIndices(ccqi) {
      this.curCategoryQuestionIndices = ccqi;
    },
    setAdminTree(tree) {
      this.treeData = tree;
      setAdminTree(tree);
    }
  }
}
</script>

<style scoped>

</style>