<template {{ $route.params.id }}>
  <div>
    <Header></Header>
    <EvaluationComponent></EvaluationComponent>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import EvaluationComponent from "@/components/EvaluationComponent.vue";

export default {
  name: "EvaluationView",
  components: {
    Header,
    EvaluationComponent,
    Footer
  },
}
</script>

<style scoped>

</style>