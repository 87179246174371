<template>
  <v-container>
    <!-- start area -->
    <v-row class="ma-0">
      <v-spacer></v-spacer>
      <v-card width="400px" class="pa-10 text-center">
        <h1>Fragebogen starten</h1>
        <v-btn
            class="mt-2"
            color="primary"
            @click="$router.push('/questions')"
        >
          <h2>Start</h2>
          <v-icon class="ml-2">mdi-arrow-right-drop-circle-outline</v-icon>
        </v-btn>
      </v-card>
      <v-spacer></v-spacer>
    </v-row>
    <h2 style="text-align: left; color: black" class="mb-10">Fragebogen Übersicht:</h2>
    <v-divider class="mt-5 mb-10"></v-divider>
    <v-timeline v-bind:dense="screenWidth < 750">
      <v-timeline-item
          v-for="i in categoryNames.length"
          :key="i"
          :color="i < timelineItemColors.length ? timelineItemColors[i - 1].colorText : 'primary'"
          large
      >
        <template v-slot:opposite>
          <span></span>
        </template>
        <v-card class="rounded-b-lg">
          <!--<v-card-title class="text-h5">{{ categoryNames[i - 1] }}</v-card-title>-->
          <v-row class="ma-0">
            <v-treeview
                :items="getQuestions(categoryNames[i - 1], categories[i - 1].questions)"
                @update:open="onTreeViewActive(i - 1)"
                open-on-click
            ></v-treeview>
            <v-img
                class="ma-5"
                v-if="screenWidth >= 750 && treeViewOpenStates[i - 1]"
                :src="categories[i - 1].img ? categories[i - 1].img : 'https://picsum.photos/510/300?randomÄ'"
                aspect-ratio="2"
            ></v-img>
          </v-row>
          <v-img
              class="ma-5"
              v-if="screenWidth < 750 && treeViewOpenStates[i - 1]"
              :src="categories[i - 1].img ? categories[i - 1].img : 'https://picsum.photos/510/300?randomÄ'"
              aspect-ratio="2"
          ></v-img>
          <!--<v-card-text>
            <p v-for="question of categories[i - 1].questions">{{ question.topic }}</p>
          </v-card-text>-->
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <v-divider class="mt-10 mb-8"></v-divider>
  </v-container>
</template>

<script>
import {categoryColors} from "@/VarHandler";

export default {
  name: "StartQuestions",
  props: {
    categories: Array,
    categoryNames: Array,
  },
  data: () => ({
    timelineItemColors: categoryColors,
    screenWidth: screen.width,
    treeViewOpenStates: [],
  }),
  mounted() {
    addEventListener('resize', event => {
      this.screenWidth = screen.width;
    });

    // init treeView open states
    this.treeViewOpenStates = Array(this.categoryNames.length).fill(false);
  },
  methods: {
    getQuestions(categoryName, questions) {
      let i = 1;
      return [{
        id: i,
        name: categoryName,
        children: Object.entries(questions).map(question => {
          return {
            id: ++i,
            name: question[1].topic,
          };
        })
      }];
    },
    onTreeViewActive(index) {
      let i = 0;
      // scuffed way to completely write new values to treeViewOpenStates array
      // -> otherwise v-if in images can not update ._.
      this.treeViewOpenStates = Array(this.categoryNames.length).fill(false).map(entry => {
        if (i++ === index)
          return !this.treeViewOpenStates[i - 1];
        return this.treeViewOpenStates[i - 1];
      });
    },
  },
}
</script>

<style scoped>
p {
  color: black;
  margin: 0;
}

h1 {
  color: #37578a;
}
</style>