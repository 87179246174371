import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"color":"darkblue pt-2 pb-2"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":_vm.breaking?12:2}},[_c(VImg,{staticClass:"ma-2 chMouse",attrs:{"contain":"","max-height":"40px","src":require("../assets/global-ct-logo-white.svg")},on:{"click":function($event){return _vm.gotoHome($event)}}})],1),_c(VCol,{attrs:{"cols":_vm.breaking?12:8}},[_c('h1',{staticClass:"white--text ma-1 font-weight-bold"},[_vm._v(" Reifegrad Prüfer ")])]),_c(VCol,{staticClass:"d-flex justify-center align-center",attrs:{"cols":_vm.breaking?12:2}})],1)],1),_c(VDivider)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }