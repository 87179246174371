<template>
  <Verification v-if="!verified" @is-auth="setVerified"></Verification>
  <QuestionsEditor v-else></QuestionsEditor>
</template>

<script>
import Verification from "@/components/admin/Verification";
import QuestionsEditor from "@/components/admin/QuestionsEditor";

export default {
  name: "EditQuestionsView",
  components: {
    QuestionsEditor,
    Verification,
  },
  data: () => ({
    verified: false,
  }),
  methods: {
    setVerified (v) {
      this.verified = v;
    }
  },

}
</script>

<style scoped>

</style>