<!--
    TODO:
-->

<template>
    <div v-if="loaded">
        <div v-html='this.spiderGraph'></div>
    </div>
</template>

<script>

export default {
  name: 'Spider',
  data(){
    return{
        loaded: false,
    }
  },
  props: {
    spiderGraph: String
  },
  mounted(){
    let parser = new DOMParser();
    let svgDoc = parser.parseFromString(this.spiderGraph, "image/svg+xml");
    let svg = svgDoc.documentElement;
    svg.removeAttribute("width");
    svg.removeAttribute("height");
    svg.removeAttribute("style");
    this.spiderGraph = new XMLSerializer().serializeToString(svgDoc);

    this.loaded = true;
  }
}
</script>

<style>
svg .hovertext{
    display: none !important;
}
svg .testHov:hover .hovertext{
    display: block !important;
}
</style>