<template>
  <v-card class="pa-3 transparent" outlined>
    <v-card-title>Frage bearbeiten</v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <v-text-field
            v-model="order"
            class="mr-3"
            label="Reihenfolge"
            type="number"
            :rules="rules.number"
        ></v-text-field>
        <v-icon class="edit-icon">mdi-pencil</v-icon>
      </v-row>
      <v-row class="ma-0">
        <v-text-field
            v-model="topic"
            class="mr-3"
            label="Thema"
            :rules="rules.required"
        ></v-text-field>
        <v-icon class="edit-icon">mdi-pencil</v-icon>
      </v-row>
      <v-card
          class="pa-10 mb-5"
          outlined
          flat
      >
        <v-row class="ma-0">
          <v-text-field
              v-model="introText"
              label="Intro Text"
              class="mr-3"
              :rules="rules.required"
          ></v-text-field>
          <v-icon class="edit-icon">mdi-pencil</v-icon>
        </v-row>
        <v-row class="ma-0">
          <v-text-field
              v-model="questionText"
              label="Frage"
              class="mr-3"
              :rules="rules.required"
          ></v-text-field>
          <v-icon class="edit-icon">mdi-pencil</v-icon>
        </v-row>
        <v-row class="mb-3 mt-3 ml-0 mr-0" v-for="(option, index) in options">
          <v-col v-if="!breaking" cols="1" class="mr-2 pa-5">
            <v-icon>mdi-circle-outline</v-icon>
          </v-col>
          <v-col :cols="breaking ? 12 : 8" class="pa-0 mr-5">
            <v-text-field
                v-model="options[index].text"
                :label="`Option ${index + 1}`"
                :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col :cols="breaking ? 10 : 1" class="pa-0">
            <v-text-field
                v-model="options[index].value"
                label="Wert"
                type="number"
                :rules="rules.number"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="pa-5">
            <v-icon class="delete-icon" @click="activateDeleteDialog(index)">mdi-delete</v-icon>
          </v-col>
        </v-row>
        <v-divider class="mb-3"></v-divider>

        {{optionsUpdater}}

        <!-- add new option -->
        <v-row v-if="!showAddOptionOverlay" class="ma-0">
          <v-spacer></v-spacer>
          <span class="mr-3">Neue Auswahloption hinzufügen</span>
          <v-icon class="add-icon" @click="showAddOptionOverlay = true">mdi-plus</v-icon>
        </v-row>
        <v-row v-else class="mb-3 mt-3 ml-0 mr-0">
          <v-col v-if="!breaking" cols="1" class="mr-2 pa-5">
            <v-icon>mdi-circle-outline</v-icon>
          </v-col>
          <v-col :cols="breaking ? 12 : 7" class="pa-0 mr-5">
            <v-text-field
                v-model="newOption.text"
                label="Option Text"
                :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col :cols="breaking ? 9 : 1" class="pa-0">
            <v-text-field
                v-model="newOption.score"
                label="Wert"
                type="number"
                :rules="rules.number"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="pa-5">
            <v-row class="ma-0">
              <v-icon class="remove-icon" color="red" @click="showAddOptionOverlay = false">mdi-minus</v-icon>
              <v-icon class="add-icon" color="green" @click="addOption()">mdi-plus</v-icon>
            </v-row>
          </v-col>
        </v-row>
        <!-- new option end -->

      </v-card>
    </v-card-text>

    <!-- delete dialog popup -->
    <v-dialog v-model="deleteDialog" width="500px">
      <v-card class="pa-5">
        <v-row class="ma-0 pa-0">
          <v-col class="pa-0" cols="11">
            <v-card-title v-if="options.length > 1">{{`Wollen Sie Option ${clickedOptionIdx + 1} wirklich löschen?`}}</v-card-title>
            <v-card-title v-else>Es muss immer mindestens eine Option existieren.</v-card-title>
          </v-col>
          <v-col class="pa-0" cols="1">
            <v-icon @click="deleteDialog = false">mdi-close</v-icon>
          </v-col>
          <v-col class="pa-0" cols="12" v-if="options.length > 1">
            <v-btn
                class="ml-3"
                color="error"
                @click="deleteOption(clickedOptionIdx)"
            >Löschen</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="deleteSnackbarProps.visible"
        timeout="2000"
    >
      <span style="color: #4CAF50">{{`Option ${deleteSnackbarProps.opIdx + 1} erfolgreich entfernt.`}}</span>
    </v-snackbar>
  </v-card>
</template>

<script>
import {getAdminTree, setQuestionInAdminTree, setChanged} from "@/VarHandler";

export default {
  name: "QuestionEdit",
  data: () => ({
    showAddOptionOverlay: false,
    newOption: {
      text: null,
      score: null,
    },
    rules: {
      required: [val => (val || '').length > 0 || 'Muss gefüllt werden!'],
      number: [val => (val && !isNaN(val) && Number(val) > 0) || 'Ungültige Zahl']
    },
    options: [],
    deleteDialog: false,
    clickedOptionIdx: 0,
    deleteSnackbarProps: {
      visible: false,
      opIdx: 0,
    },
  }),
  props: {
    curCategoryQuestionIndices: Object,
  },
  computed: {
    breaking() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'lg';
    },
    order: {
      get() {
        return getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex].order;
      },
      set(value) {
        if (isNaN(value)) // check if is valid number
          return null;
        let questionObj = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex];
        questionObj.order = value;
        setQuestionInAdminTree(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, questionObj);
        setChanged(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, true);
        this.$emit('tree-change', getAdminTree());
      }
    },
    topic: {
      get() {
        return getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex].name;
      },
      set(value) {
        let questionObj = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex];
        questionObj.name = value;
        setQuestionInAdminTree(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, questionObj);
        setChanged(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, true);
        this.$emit('tree-change', getAdminTree());
      }
    },
    introText: {
      get() {
        return getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex].introductionText;
      },
      set(value) {
        let questionObj = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex];
        questionObj.introductionText = value;
        setQuestionInAdminTree(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, questionObj);
        setChanged(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, true);
      }
    },
    questionText: {
      get() {
        return getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex].question;
      },
      set(value) {
        let questionObj = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex];
        questionObj.question = value;
        setQuestionInAdminTree(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, questionObj);
        setChanged(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, true);
      }
    },
    optionsUpdater() {
      this.options = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex].options;
    },
  },
  watch: {
    options: {
      deep: true,
      handler: function (newVal) {
        let questionObj = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex];
        questionObj.options = newVal;
        setQuestionInAdminTree(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, questionObj);
        setChanged(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, true);
      }
    }
  },
  methods: {
    activateDeleteDialog(index) {
      this.deleteDialog = true;
      this.clickedOptionIdx = index;
    },
    deleteOption(index) {
      let questionObj = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex];
      questionObj.options.splice(index, 1);
      setQuestionInAdminTree(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, questionObj);
      this.deleteSnackbarProps.opIdx = index;
      this.deleteSnackbarProps.visible = true;
      this.deleteDialog = false;
    },
    addOption() {
      let question = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].children[this.curCategoryQuestionIndices.questionIndex];
      question.options.push({
        text: this.newOption.text,
        value: this.newOption.score,
      });
      setQuestionInAdminTree(this.curCategoryQuestionIndices.categoryIndex, this.curCategoryQuestionIndices.questionIndex, question);
      this.showAddOptionOverlay = false;
    }
  },
}
</script>

<style scoped>
  .delete-icon:hover {
    color: red;
  }
  .edit-icon:hover {
    color: cornflowerblue;
  }
  .add-icon:hover {
    color: green;
    background-color: #acacac;
  }
  .remove-icon:hover {
    color: red;
    background-color: #acacac;
  }
</style>