<template>
  <v-card class="pa-3 transparent" outlined>
    <v-card-title>Handlungsfeld bearbeiten</v-card-title>
    <v-card-text>
      <v-row class="ma-0">
        <v-text-field
            class="mr-3"
            v-model="order"
            label="Reihenfolge"
            type="number"
            :rules="rules.number"
        ></v-text-field>
        <v-icon class="edit-icon">mdi-pencil</v-icon>
      </v-row>
      <v-row class="ma-0">
        <v-text-field
            class="mr-3"
            v-model="themeName"
            label="Bezeichnung"
        ></v-text-field>
        <v-icon class="edit-icon">mdi-pencil</v-icon>
      </v-row>
      <v-hover v-slot="{ hover }">
        <v-card class="pa-1" outlined flat>
          <input type="file" id="imgFile" ref="imgFile" style="display: none" @change="previewImageUpload">
          <v-img
              :src="image ? imagePreview : imgPlaceholder"
              :aspect-ratio="16/9"
          >
            <v-fade-transition>
              <v-overlay
                  v-if="hover"
                  absolute
                  color="gray"
                  @click="$refs.imgFile.click()"
              >
                <v-icon size="50pt">mdi-image-edit-outline</v-icon>
              </v-overlay>
            </v-fade-transition>
          </v-img>
          <v-file-input
              v-model="image"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Bild hochladen"
              prepend-icon="mdi-camera"
              @change="selectImage"
              @click:clear="clearImagePreview()"
              label="Bild"
              show-size
              counter
          ></v-file-input>
        </v-card>
      </v-hover>
    </v-card-text>
  </v-card>
</template>

<script>
import {getAdminTree, setCategoryNameInAdminTree, setCategoryImgInTree, setCategoryImagePathInTree, setCategoryOrder, setChanged, reorderTree} from "@/VarHandler";
export default {
  name: "ThemeEdit",
  props: {
    curCategoryQuestionIndices: Object,
  },
  data: () => ({
    imgPlaceholder: 'https://picsum.photos/510/300?random',
    image: null,
    imagePreview: null,
    rules: {
      number: [val => (val && !isNaN(val) && Number(val) > 0) || 'Ungültige Zahl']
    },
  }),
  computed: {
    themeName: {
      get() {
        const tree = getAdminTree();
        if (this.curCategoryQuestionIndices === undefined || !tree[this.curCategoryQuestionIndices.categoryIndex])
          return null;
        return tree[this.curCategoryQuestionIndices.categoryIndex].name;
      },
      set(value) {
        if(this.curCategoryQuestionIndices === undefined || !getAdminTree())
          return;
        setCategoryNameInAdminTree(this.curCategoryQuestionIndices.categoryIndex, value);
        setChanged(this.curCategoryQuestionIndices.categoryIndex, undefined, true);
        this.$emit('tree-change', getAdminTree());
        return value;
      },
    },
    order: {
      get() {
        const tree = getAdminTree();
        if (this.curCategoryQuestionIndices === undefined || !tree[this.curCategoryQuestionIndices.categoryIndex])
          return null;
        return tree[this.curCategoryQuestionIndices.categoryIndex].order;
      },
      set(value) {
        if (isNaN(value)) // check if is valid number
          return null;
        if(this.curCategoryQuestionIndices === undefined || !getAdminTree())
          return;
        setCategoryOrder(this.curCategoryQuestionIndices.categoryIndex, value);
        reorderTree();
        setChanged(this.curCategoryQuestionIndices.categoryIndex, undefined, true);
        this.$emit('tree-change', getAdminTree());
        return value;
      }
    }
  },
  watch: {
    curCategoryQuestionIndices: {
      immediate: true,
      deep: true,
      handler() {
        const tree = getAdminTree();
        if (tree && this.curCategoryQuestionIndices.categoryIndex !== undefined) {
          this.themeName = tree[this.curCategoryQuestionIndices.categoryIndex].name;

          // init image (path) and imagePreview from backend or local changes
          const img = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].img;
          if (img)
            this.imagePreview = img;
          else
            this.imagePreview = null;
          const imgPath = getAdminTree()[this.curCategoryQuestionIndices.categoryIndex].imagePath;
          if(imgPath)
            this.image = imgPath;
          else
            this.image = null;

          // first load check
          if (this.imagePreview && !this.image)
            this.image = {};
        }
      },
    },
  },
  methods: {
    async previewImageUpload(event) {
      const file = event.target.files[0];
      this.image = file;
      setCategoryImagePathInTree(this.curCategoryQuestionIndices.categoryIndex, this.image);
      await this.selectImage(file);
      setChanged(this.curCategoryQuestionIndices.categoryIndex, undefined, true);
    },
    async selectImage(event) {
      const file = event;
      setCategoryImagePathInTree(this.curCategoryQuestionIndices.categoryIndex, file);
      console.log(file);
      if (!file)
        return;
      const readData = (f) => new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(f);
      });

      this.imagePreview = await readData(file);
      console.log(this.imagePreview);
      setCategoryImgInTree(this.curCategoryQuestionIndices.categoryIndex, this.imagePreview);
    },
    async clearImagePreview() {
      this.imagePreview = null;
      setCategoryImgInTree(this.curCategoryQuestionIndices.categoryIndex, this.imagePreview);
    },
  }
}
</script>

<style scoped>
.edit-icon:hover {
  color: cornflowerblue;
}
</style>