import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pa-3 transparent",attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Handlungsfeld bearbeiten")]),_c(VCardText,[_c(VRow,{staticClass:"ma-0"},[_c(VTextField,{staticClass:"mr-3",attrs:{"label":"Reihenfolge","type":"number","rules":_vm.rules.number},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_c(VIcon,{staticClass:"edit-icon"},[_vm._v("mdi-pencil")])],1),_c(VRow,{staticClass:"ma-0"},[_c(VTextField,{staticClass:"mr-3",attrs:{"label":"Bezeichnung"},model:{value:(_vm.themeName),callback:function ($$v) {_vm.themeName=$$v},expression:"themeName"}}),_c(VIcon,{staticClass:"edit-icon"},[_vm._v("mdi-pencil")])],1),_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"pa-1",attrs:{"outlined":"","flat":""}},[_c('input',{ref:"imgFile",staticStyle:{"display":"none"},attrs:{"type":"file","id":"imgFile"},on:{"change":_vm.previewImageUpload}}),_c(VImg,{attrs:{"src":_vm.image ? _vm.imagePreview : _vm.imgPlaceholder,"aspect-ratio":16/9}},[_c(VFadeTransition,[(hover)?_c(VOverlay,{attrs:{"absolute":"","color":"gray"},on:{"click":function($event){return _vm.$refs.imgFile.click()}}},[_c(VIcon,{attrs:{"size":"50pt"}},[_vm._v("mdi-image-edit-outline")])],1):_vm._e()],1)],1),_c(VFileInput,{attrs:{"accept":"image/png, image/jpeg, image/bmp","placeholder":"Bild hochladen","prepend-icon":"mdi-camera","label":"Bild","show-size":"","counter":""},on:{"change":_vm.selectImage,"click:clear":function($event){return _vm.clearImagePreview()}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }