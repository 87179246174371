<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "AmpleSoft";
  src: local("AmpleSoft"),
    url("./fonts/AmpleSoft.otf") format("truetype");
}

$body-font-family: AmpleSoft, serif;

.v-application {
  font-family: $body-font-family, AmpleSoft, serif !important;
}
</style>
