<template>
  <!-- root div -->
  <div>
    <Header></Header>
    <Loading v-if="!fullyInit" :error-message="errorMessage"></Loading>
    <QuestionComponent v-if="fullyInit" :categories="categories" :category-names="categoryNames"
                       :user-answers="userAnswers" :paths="paths"></QuestionComponent>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import QuestionComponent from "@/components/QuestionComponent";
import UserInfos from "@/components/UserInfos";
import Footer from "@/components/Footer";
import {api} from "@/api";
import Loading from "@/components/Loading";
import {setCompanyIndustryOptions, setCompanySizeOptions} from "@/VarHandler";

export default {
  name: 'QuestionView',
  components: {
    Loading,
    Footer,
    UserInfos,
    QuestionComponent,
    Header
  },
  data: () => ({
    categories: [],
    userAnswers: {},
    paths: {
      surveyPath: '/api/survey',
      indexDataPath: 'indexData',
      answersStoragePath: 'userAnswers',
    },
    fullyInit: false,
    errorMessage: null,
    infoTextVisible: true,
  }),
  async mounted() {
    await api.get(this.paths.surveyPath).then(resp => {
      // axios get success
      this.categories = resp.categories;
      setCompanyIndustryOptions(resp.companyIndustryOptions);
      setCompanySizeOptions(resp.companySizeOptions);
      
    }).catch(error => {
      // axios get error (database/backend down)
      console.log(error.message);
      this.errorMessage = "Can't load questions.";
    });

    // axios get error thrown
    if(this.errorMessage)
      return;

    // look up for existing data
    const data = JSON.parse(localStorage.getItem(this.paths.answersStoragePath));

    // if data are existing fill empty local storage json obj
    if (data)
      this.userAnswers = data;

    // finished with initialization
    this.fullyInit = true;
  },
  computed: {
    categoryNames() {
      if (!this.categories)
        return [];
      return this.categories.map(c => c.name);
    }
  },
  methods: {
  },
}
</script>
