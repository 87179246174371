import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#4f89c8",
                secondary: "#424242",
                accent: "#82B1FF",
                error: "#FF5252",
                info: "#37578a",
                success: "#4CAF50",
                warning: "#FFC107",
                lightblue: "#14c6FF",
                yellow: "#FFCF00",
                pink: "#FF1976",
                orange: "#FF8657",
                magenta: "#C33AFC",
                darkblue: "#1E2D56",
                gray: "#909090",
                neutralgray: "#3a5c77",
                green: "#5db77a",
                red: "#FF5c4E",
                darkblueshade: "#21335b",
                lightgray: "#BDBDBD",
                lightpink: "#FFCFE3",
                white: "#eefbfb"
            }
        }
    }
});
