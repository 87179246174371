
<template>
    <v-container>
        <v-card color="darkblueshade" class="pa-8">
            <Loading v-if="!loaded" :error-message="errorMessage"></Loading>
            <div v-if="loaded">
                <h2 class="white--text ma-1 font-weight-bold">Ihr Ergebnis: {{maturityLevel}}</h2>
                <p class="white--text ma-1 text-justify">{{maturityDescription}}</p>
                <v-row>
                    <v-col :cols="breaking ? 12 : 5">
                        <SpiderGraph :spiderGraph="spiderGraph"></SpiderGraph>
                        <p class="description">Diese Grafik bietet eine Übersicht des Fortschritts der Digitalisierung im Vergleich zu den Durchschnittlichen Umfragewerten.</p>
                    </v-col>
                    <v-col :cols="breaking ? 12 : 7" class="d-flex justify-center align-center">
                        <div class="ma-0">
                                <v-simple-table class="darkblueshade" dark>
                                <thead>
                                    <tr>
                                        <th class="white--text"> Farbe</th>
                                        <th class="white--text"> Handlungsfeld</th>
                                        <th class="white--text"> Ihr Rang</th>
                                        <th v-if="!breaking" class="white--text"> Durchschnitt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(eintrag, index) in userAverage" :key="('eintrag' + index)">
                                        <td><svg width="25" height="20"><rect x="4" y="0" :width="rect_width" :height="rect_width" :fill="index < colors.length ? colors[index].colorHex : colors[0].colorHex"/></svg></td>
                                        <td><p class="txt-left  align-center">{{eintrag.category}}</p></td>
                                        <td><p class="txt-left">{{eintrag.maturityLevel}}</p></td>
                                        <td v-if="!breaking"><p class="txt-left">{{getGlobalValue(index)}}</p></td>
                                    </tr>
                                </tbody>
                            </v-simple-table> 
                        </div>
                    </v-col>
                </v-row>
                <h3 class="white--text mt-2 text-center">Die Ergebnisse wurden an Ihre Mail-Adresse versand. Kontaktieren Sie uns gerne bei Fragen</h3>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import SpiderGraph from "@/components/Spider.vue";
import {categoryColors} from "@/VarHandler";
import Loading from "@/components/Loading.vue";
import {api} from "@/api";


export default {
    name: "EvaluationComponent",
    components:{
        SpiderGraph,
        Loading
    },
    data(){
        return {
        rect_width: 18,
        loaded: false,
        colors: [],
        userAverage: [],
        globalAverage: [],
        maturityLevel: "",
        maturityDescription: "",
        errorMessage: "",
        spiderGraph: "",
        }
    },
    computed: {
        breaking(){
            return this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm";
        }
    },
    methods: {
        getGlobalValue(index){
            let category = this.userAverage[index].category;
            //console.log(category);
            for(let i = 0; i < this.globalAverage.length; i++){
                //console.log(this.globalAverage[i]);
                if(this.globalAverage[i].category == category) return this.globalAverage[i].maturityLevel;
            }
            return "not found"
        }
    },
    async mounted(){
        this.colors = categoryColors;
        await api.get("/api/survey/replies/" + this.$route.params.id).then(resp => {
                this.userAverage = resp.userAverage;
                this.globalAverage = resp.globalAverage;
                this.maturityDescription = resp.maturityLevelDescription;
                this.maturityLevel = resp.maturityLevel;
                this.spiderGraph = resp.spiderGraph
                
                this.loaded = true;
            }).catch(error => {
                // axios get error (database/backend down)
                console.log(error.message);
                this.errorMessage = "Something went wrong :(";
        });
    }
}
</script>

<style>
.description{
    text-align: center;
    font-size: small;
}
</style>