<template>
  <v-container class="fill-height container--fluid text-center">
    <v-row align="center" justify="center">
      <v-card class="pa-10">
        <v-row>
          <v-spacer></v-spacer>
          <v-icon>mdi-clipboard-edit-outline</v-icon>
        </v-row>
        <h2 style="color: black" class="mb-5">Admin Verifikation</h2>
        <v-text-field
            v-model="adminName"
            class="ml-2 mr-4"
            label="Name"
            prepend-icon="mdi-account"
        ></v-text-field>
        <v-text-field
            v-model="adminPass"
            class="ml-2 mr-4"
            type="password"
            label="Passwort"
            prepend-icon="mdi-key-variant"
        ></v-text-field>
        <v-btn
            class="mt-2 mb-4 mr-10"
            @click="sendAuth()"
            outlined
        >Senden</v-btn>
        <v-card-text style="text-align: left">
          Der Nutzername und das Passwort wird benötigt um als verifizierter Admin Zugang zu der Editieroberfläche zu erhalten.
        </v-card-text>
      </v-card>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        timeout="4000"
    >
      <span style="color: #FF5252">Ungültige Anmeldedaten!</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import {api, setAuth} from "@/api";
export default {
  name: "Verification",
  data: () => ({
    adminName: null,
    adminPass: null,
    snackbar: false,
  }),
  methods: {
    async sendAuth() {
      setAuth(this.adminName, this.adminPass);
      const allGood = await api.get('api/question');
      console.log(allGood);
      if (allGood)
          this.$emit('is-auth', true);
      else
        this.snackbar = true;
    },
  }
}
</script>

<style scoped>

</style>