<template>
  <!-- root div -->
  <div>
    <Header></Header>
    <v-container class="mt-10 mb-0">
      <h4>Willkommen im Accessment Center von globalCT</h4>
      <p style="color: black">{{info_text}}</p>
    </v-container>
    <Loading v-if="!fullyInit" :error-message="errorMessage"></Loading>
    <StartQuestions v-if="fullyInit" :categories="categories" :category-names="categoryNames"></StartQuestions>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import StartQuestions from "@/components/StartQuestions";
import {api} from "@/api";
import Loading from "@/components/Loading";
export default {
  name: "HomeView",
  components: {
    Loading,
    StartQuestions,
    Footer,
    Header
  },
  data: () => ({
    categories: [],
    fullyInit: false,
    errorMessage: null,
    info_text: "Der folgende Fragebogen bietet ihnen die Möglichkeit den Satand der Digitalisierung in Ihrem Unternehmen inzuschätzen. Wie Sie in der Übersicht erkennen, ist der Fragebogen in verschiedene Handlungsfelder eingeteilt. Zu den Fragen bieten wir mehrere Aussagen als Antwortmöglichkeit an. Wählen Sie eine Aussage, die am ehhesten auf Ihr Unternehmen zutrifft. Am Ende des Fragebogens werden wir Sie bitten eine E-mail Adresse anzugeben. Anschließend bekommen sie die Auswertung angezeigt und an Ihre Mailadresse gesand.",
  }),
  async mounted() {
    await api.get('/api/survey').then(resp => {
      // axios get success
      this.categories = resp.categories;
    }).catch(error => {
      // axios get error (database/backend down)
      console.log(error.message);
      this.errorMessage = "Can't load preview.";
    });

    // axios get error thrown
    if(this.errorMessage)
      return;

    this.fullyInit = true;
  },
  computed: {
    categoryNames() {
      if (!this.categories)
        return [];
      return this.categories.map(c => c.name);
    }
  },
}
</script>

<style scoped>

</style>