<template>
  <v-container class="pb-0">
    <v-stepper class="mt-n2 mb-n4" style="box-shadow: none" v-model="stepper" alt-labels>
      <v-stepper-header style="box-shadow: none">
        <template v-for="(category, i) in categoryNames">
          <v-stepper-step class="mb-n4" :complete="stepper > i + 1" :step="i + 1">{{ category }}</v-stepper-step>
          <v-divider></v-divider>
        </template>
        <v-stepper-step :complete="stepper > categoryNames.length + 1" :step="categoryNames.length + 1">Ihre Daten
        </v-stepper-step>
      </v-stepper-header>
      <v-divider></v-divider>
      <v-stepper-items>
        <v-stepper-content v-for="j in categoryNames.length" :step="j">
          <!-- questions/answers container -->
          <v-card
              class="pa-5"
              color="darkblueshade"
          >
            <div v-if="showCategoryImg">
              <h3 class="white--text ma-1 font-weight-light">{{ categories[stepper - 1].name }}</h3>
              <v-divider dark class="mb-5"></v-divider>
              <v-row justify="center">
                <v-img
                    :src="categories[stepper - 1].img ? categories[stepper - 1].img : require(`@/assets/placeholderImg.png`)"
                    :aspect-ratio="16/9"
                    max-width="1000px"
                    max-height="500px"
                ></v-img>
              </v-row>
            </div>
            <div v-else>
              <!-- question number intro container -->
              <h3 class="white--text ma-1 font-weight-light">{{ curQuestion.topic }}</h3>
              <v-divider dark class="mb-5"></v-divider>
              <v-row class="mb-0">
                <h4 class="white--text mt-4 ml-4">Frage {{ curQuestionIndex + 1 }}</h4>
                <p class="mt-4 ml-4 ">{{ curQuestion.introductionText }}</p>
              </v-row>
              <p class="mb-0 ml-1 intro-text">{{ curQuestion.question }}</p>
              <!-- answers list container-->
              <v-list class="ma-0 transparent">
                <v-list-item-group id="listItemGroup" active-class="answerBorder" v-model="itemsGroup" color="primary">
                  <v-list-item
                      class="rounded-lg ma-2 list-item regular-item"
                      v-for="(option, i) in curQuestion.options"
                      :key="i + 1"
                      :value="i + 1"
                  >
                    <template>
                      <v-radio-group v-model="itemsGroup">
                        <v-radio color="white" :key="i + 1" :value="i + 1" readonly>
                          <template v-slot:label>
                            <div class="white--text">{{ option.text }}</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </template>
                  </v-list-item>
                  <v-list-item
                      class="rounded-lg ma-2"
                      :key="curQuestion.options.length + 1"
                      :value="curQuestion.options.length + 1"
                  >
                    <template>
                      <v-radio-group v-model="itemsGroup">
                        <v-radio color="white" :key="curQuestion.options.length + 1"
                                 :value="curQuestion.options.length + 1" readonly>
                          <template v-slot:label>
                            <div class="white--text">Keine dieser Aussagen trifft zu.</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-stepper-content>
        <v-stepper-content :step="categoryNames.length + 1">
          <UserInfos @valid-updated="getIfInfoDataAreValid" @info-updated="getContactInfo" ></UserInfos>
        </v-stepper-content>
      </v-stepper-items>
      <!-- question navigation -->
      <div class="pb-6 px-6">
        <v-progress-linear class="rounded-sm pt-0" height="8pt"
                           :value="((curQuestionIndex) / (categories[stepper - 1] ? categories[stepper - 1].questions.length : (curQuestionIndex + 1))) * 100"></v-progress-linear>
      </div>
      <v-row class="ml-6 mr-6 mb-6">
        <v-btn
            color="primary"
            @click="previousStep()"
            :disabled="stepper <= 1 && curQuestionIndex <= 0 && this.showCategoryImg"
        >
          Zurück
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            @click="nextStep()"
            :disabled="(!itemsGroup && !showCategoryImg) && !validUserInfos"
        >
          {{ stepper < categoryNames.length + 1 ? 'Weiter' : 'Senden' }}
        </v-btn>
      </v-row>
    </v-stepper>
  </v-container>
</template>

<script>
import UserInfos from "@/components/UserInfos";
import {api} from "@/api";

export default {
  name: 'QuestionComponent',
  components: {
    UserInfos
  },
  data: () => ({
    stepper: 1,
    curQuestionIndex: 0,
    itemsGroup: 0,
    radioGroup: 1,
    validUserInfos: false,
    contactInfo: {},
    showCategoryImg: true,
  }),
  props: {
    categories: Array,
    categoryNames: Array,
    userAnswers: Object,
    paths: Object,
  },
  async mounted() {
    // scuffed way to wait for userAnswers init in HomeView component
    const placeholder = this.initOptionsFromUserAnswers;
    // check for not at start of category -> hide category start image
    if (this.curQuestionIndex > 0 || this.stepper > 1)
      this.showCategoryImg = false;
    
    document.addEventListener("keydown", (e) =>{
      if(e.code === ("Enter") && !((!this.itemsGroup && !this.showCategoryImg) && !this.validUserInfos)){
        e.preventDefault();
        this.nextStep().then();
      }
    },false)
  },
  computed: {
    initOptionsFromUserAnswers() {
      if (this.categories) {
        this.searchForNextNotAnsweredQuestion();
        return this.loadAnswerFromBrowserStorage();
      }
    },
    curQuestion() {
      if (this.categories[this.stepper - 1])
        return this.categories[this.stepper - 1].questions[this.curQuestionIndex];

      // placeholders
      return {
        topic: "Can't load topic",
        introductionText: "Can't load intro text",
        question: "Can't load question",
        options: [],
      };
    },
  },
  methods: {
    saveAnswersInBrowserStorage() {
      // if user picked an option -> save
      if (this.itemsGroup) {
        // write picked answer in user answers obj
        const curQuestion = this.categories[this.stepper - 1].questions[this.curQuestionIndex];
        this.userAnswers[curQuestion.id.toString()] = this.itemsGroup <= curQuestion.options.length ? curQuestion.options[this.itemsGroup - 1].id : null;
        localStorage.setItem(this.paths.answersStoragePath, JSON.stringify(this.userAnswers));
      }
    },
    loadAnswerFromBrowserStorage() {
      const data = JSON.parse(localStorage.getItem(this.paths.answersStoragePath));
      // check for existing local browser storage data and prevent stepper out of bounds
      if (data && this.stepper <= this.categoryNames.length) {
        const curQuestion = this.categories[this.stepper - 1].questions[this.curQuestionIndex];
        const optionsLen = curQuestion.options.length;

        // user skipped this answer
        if (data[curQuestion.id] === null) {
          this.itemsGroup = optionsLen + 1;
          return;
        }

        // get option index
        let newIndex = -1;
        for (let i = 0; i < optionsLen; i++) {
          if (curQuestion.options[i].id === data[curQuestion.id])
            newIndex = i;
        }
        this.itemsGroup = newIndex + 1;
        return;
      }

      // reset options because there is no data saved
      this.itemsGroup = 0;
    },
    previousStep() {
      // save/overwrite answers
      this.saveAnswersInBrowserStorage();

      // show image of first category
      if (this.curQuestionIndex === 0 && !this.showCategoryImg && this.stepper !== this.categoryNames.length + 1) {
        this.showCategoryImg = true;
        return;
      }

      // decrement question index counter
      this.curQuestionIndex--;

      // reached last question of previous Handlungsfeld
      if (this.curQuestionIndex < 0) {
        // check for reached first question of questionnaire
        if (this.stepper - 1 > 0) {
          // decrement stepper
          this.stepper--;
          // set question index counter
          this.curQuestionIndex = this.categories[this.stepper - 1].questions.length - 1;

          // show previous category image
          this.showCategoryImg = false;
        }
      }

      // load answer if existing
      this.loadAnswerFromBrowserStorage();
    },
    searchForNextNotAnsweredQuestion () {
      // goes to last updated position after a website reload
      const data = JSON.parse(localStorage.getItem(this.paths.answersStoragePath));
      for (let i = this.stepper - 1; i < this.categoryNames.length; i++) {
        for (let j = this.curQuestionIndex; j < this.categories[i].questions.length; j++) {
          // found unanswered question
          if (!(this.categories[i].questions[j].id in data)) {
            this.stepper = i + 1;
            this.curQuestionIndex = j;
            return;
          }
        }
        // reached end
        if(i === this.categoryNames.length - 1) {
          this.stepper = this.categoryNames.length + 1;
          return;
        }
      }
    },
    async nextStep() {
      // send user data
      if (this.stepper === this.categoryNames.length + 1) {
        const obj = {
          contactInfo: this.contactInfo,
          replies: Object.entries(this.userAnswers).map(([k, v]) => {
            return {
              questionId: k,
              optionId: v,
            };
          }),
        };
        // send user info data to backend server
        await api.post('/api/survey/replies', obj).then(resp => {
          localStorage.removeItem("userAnswers");
          this.$router.push('/evaluation/' + resp.id);
        });
        return;
      }

      // save/overwrite answers
      this.saveAnswersInBrowserStorage();

      // hide category image in next step
      if (this.curQuestionIndex === 0 && this.showCategoryImg) {
        this.showCategoryImg = false;
      } else {
        // increment question index counter
        this.curQuestionIndex++;
      }

      // reached last question for current Handlungsfeld
      if (this.curQuestionIndex === this.categories[this.stepper - 1].questions.length) {
        // reset question index counter
        this.curQuestionIndex = 0;
        // increment stepper
        this.stepper++;

        // is at first index in new category
        if (!this.showCategoryImg && this.stepper !== this.categoryNames.length + 1) {
          // show category image
          this.showCategoryImg = true;
          this.itemsGroup = 0;
          return;
        }
      }

      // load answer if existing
      this.loadAnswerFromBrowserStorage();
    },
    getContactInfo(info) {
      this.contactInfo = info;
    },
    getIfInfoDataAreValid (valid) {
      this.validUserInfos = valid;
    },
  }
}
</script>

<style>
p {
  font-family: AmpleSoft, serif;
  text-align: left;
  color: #eefbfb;
  font-weight: normal;
}

.intro-text {
  font-size: 11pt;
}

.list-title {
  color: #eefbfb;
}

.list-item {
  background: #3a5c77;
}

.list-item:hover {
  background: #31536c;
}

.answerBorder.regular-item {
  outline: #eefbfb solid 1pt;
}

.v-radio .v-icon {
  color: #eefbfb !important;
}

.v-stepper__label {
  text-align: center !important;
}
</style>
