import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pa-3",attrs:{"outlined":""}},[_c(VTreeview,{attrs:{"items":_vm.tree,"active":_vm.active,"activatable":"","open-on-click":""},on:{"update:active":[function($event){_vm.active=$event},_vm.activated],"update:open":_vm.opened},scopedSlots:_vm._u([{key:"append",fn:function({active}){return [(active)?_c(VIcon,{staticClass:"remove-icon",on:{"click":function($event){_vm.removeDialog = true}}},[_vm._v(" mdi-delete ")]):_vm._e(),(_vm.removeDialog)?_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Möchten Sie dieses Fragebogenelement wirklich löschen?")]),_c(VCardText,[_vm._v("Nach dem Löschen ist das Wiederherstellen nicht mehr rückgänig zu machen! Die Datenbank wird nach löschen des Elements aktualisiert. ")]),_c(VBtn,{staticClass:"ma-5",attrs:{"color":"red"},on:{"click":function($event){return _vm.removeElement()}}},[_vm._v("Löschen")])],1)],1):_vm._e()]}}])}),_c(VSnackbar,{attrs:{"timeout":"4000"},model:{value:(_vm.snackbar.on),callback:function ($$v) {_vm.$set(_vm.snackbar, "on", $$v)},expression:"snackbar.on"}},[(_vm.snackbar.error)?_c('span',{staticStyle:{"color":"#FF5252"}},[_vm._v(_vm._s(_vm.snackbar.text))]):_c('span',{staticStyle:{"color":"#4CAF50"}},[_vm._v(_vm._s(_vm.snackbar.text))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }