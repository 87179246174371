const categoryColors = [
    {
        colorText: 'primary',
        colorHex: "#4f89c8",
    },
    {
        colorText: 'info',
        colorHex: "#37578a",
    },
    {
        colorText: 'success',
        colorHex: "#4CAF50",
    },
    {
        colorText: 'warning',
        colorHex: "#FFC107",
    },
    {
        colorText: 'orange',
        colorHex: "#FF8657",
    },
    {
        colorText: 'darkblue',
        colorHex: "#1E2D56",
    },
    {
        colorText: 'neutralgray',
        colorHex: "#3a5c77",
    },
    {
        colorText: 'green',
        colorHex: "#5db77a",
    },
    {
        colorText: 'lightpink',
        colorHex: "#FFCFE3",
    },
];

let surveyReply = null;
let companyIndustryOptions = null;
let companySizeOptions = null;

function setSurveyReply(surveyR) {
    let dummy = {
        "maturityLevelDescription": "Herzlich Willkommen am Anfang Ihrer Reise. Der rasante Wandel der Umwelt und die Auswirkungen daraus, wirken sich auf viele Branchen aus. Mit dem richtigen Partner an Ihrer Seite können Sie sich diesem anpassen und die digitale Transformation gestalten. Auf grüner Wiese können Sie nun gemeinsam mit einem Full-Service-Dienstleister, neuste Technologien und Methoden implementieren.",
        "maturityLevel": "Absolute Beginner",
        "userAverage": [
            {
                "maturityIndex": 0,
                "maturityLevel": "Absolute Beginner",
                "category": "Prozesse",
                "value": 10.5
            },
            {
                "maturityIndex": 1,
                "maturityLevel": "Beginner",
                "category": "Strategie",
                "value": 20.5
            },
            {
                "maturityIndex": 5,
                "maturityLevel": "Expert",
                "category": "Technologie & Daten",
                "value": 40.5
            },
            {
                "maturityIndex": 2,
                "maturityLevel": "Starting Explorer",
                "category": "Kultur &    Organisation",
                "value": 40.5
            },
            {
                "maturityIndex": 7,
                "maturityLevel": "Pionier",
                "category": "Kunde",
                "value": 50.5
            }
        ],
        "globalAverage": [
            {
                "maturityIndex": 0,
                "maturityLevel": "Absolute Beginner",
                "category": "Prozesse",
                "value": 40.5
            },
            {
                "maturityIndex": 0,
                "maturityLevel": "Absolute Beginner",
                "category": "Strategie",
                "value": 40.5
            },
            {
                "maturityIndex": 0,
                "maturityLevel": "Absolute Beginner",
                "category": "Technologie & Daten",
                "value": 40.5
            },
            {
                "maturityIndex": 0,
                "maturityLevel": "Absolute Beginner",
                "category": "Kultur & Organisation",
                "value": 40.5
            },
            {
                "maturityIndex": 0,
                "maturityLevel": "Absolute Beginner",
                "category": "Kunde",
                "value": 40.5
            },
        ]
    }
    surveyReply = surveyR;
}

function getSurveyReply() {
    return surveyReply;
}

function setCompanySizeOptions(comSO) {
    companySizeOptions = comSO;
}

function getCompanySizeOptions() {
    return companySizeOptions;
}

function setCompanyIndustryOptions(comIO) {
    companyIndustryOptions = comIO;
}

function getCompanyIndustryOptions() {
    return companyIndustryOptions;
}

// -------- admin page stuff --------
import {api} from "@/api";

let adminTree = [];

async function initAdminPage() {
    adminTree = [];
    await api.get('api/category').then(async resp => {
        adminTree = resp;

        let idCounter = 0;
        for (let categoryIdx = 0; categoryIdx < adminTree.length; categoryIdx++) {
            adminTree[categoryIdx].id = ++idCounter;
            adminTree[categoryIdx].isSubTopic = false;
            adminTree[categoryIdx].topicIndex = categoryIdx;
            adminTree[categoryIdx].imagePath = null;
            adminTree[categoryIdx].changed = false;

            let questions = [];
            questions = await api.get(`api/question/findByCategory/${adminTree[categoryIdx].name}`);
            for (let questionIdx = 0; questionIdx < questions.length; questionIdx++) {
                questions[questionIdx].dbId = questions[questionIdx].id;
                questions[questionIdx].id = ++idCounter;
                questions[questionIdx].name = questions[questionIdx].topic;
                questions[questionIdx].isSubTopic = true;
                questions[questionIdx].subTopicIndex = questionIdx;
                questions[questionIdx].topicIndex = categoryIdx;
                questions[questionIdx].children = [];
                questions[questionIdx].changed = false;
            }

            adminTree[categoryIdx].children = questions;
        }

        // set right order
        //reorderTree(); // TODO broken

    }).catch(err => {
        console.log(err);
    });
}

function reorderTree() {
    const filter = (a, b) => {
        const oA = a.order, oB = b.order;
        const nA = a.name, nB = b.name;
        if (oA < oB || nA) return -1;
        if (oA > oB) return 1;
        if (nA < nB) return -1;
        if (nA > nB) return 1;
        return 0;
    };
    // reorder categories
    adminTree.sort(filter);
    // reorder questions in categories
    for (let i = 0; i < adminTree.length; i++)
        adminTree[i].children.sort(filter);
}

function setAdminTree(tree) {
    adminTree = tree;
}

function setCategoryNameInAdminTree(categoryIdx, name) {
    adminTree[categoryIdx].name = name;
}

function setCategoryImgInTree(categoryIdx, img) {
    adminTree[categoryIdx].img = img;
}

function setCategoryImagePathInTree(categoryIdx, path) {
    adminTree[categoryIdx].imagePath = path;
}

function setCategoryOrder(categoryIdx, order) {
    adminTree[categoryIdx].order = order;
}

function setChanged(categoryIdx, questionIdx, state) {
    if (questionIdx !== undefined)
        adminTree[categoryIdx].children[questionIdx].changed = state;
    else
        adminTree[categoryIdx].changed = state;

    // TODO reload tree
}

function getAdminTree() {
    return adminTree;
}

function setQuestionInAdminTree(categoryIdx, questionIdx, value) {
    adminTree[categoryIdx].children[questionIdx] = value;
}


export {
    categoryColors,
    setSurveyReply,
    getSurveyReply,
    setCompanyIndustryOptions,
    getCompanyIndustryOptions,
    setCompanySizeOptions,
    getCompanySizeOptions,
    initAdminPage,
    getAdminTree,
    setAdminTree,
    setCategoryNameInAdminTree,
    setCategoryImgInTree,
    setCategoryImagePathInTree,
    setCategoryOrder,
    setQuestionInAdminTree,
    setChanged,
    reorderTree,
}