<template>
  <v-card class="pa-3" outlined>
    <v-treeview
        :items="tree"
        :active.sync="active"
        activatable
        open-on-click
        @update:open="opened"
        @update:active="activated"
    >
      <template v-slot:append="{active}">
        <v-icon
            v-if="active" class="remove-icon"
            @click="removeDialog = true"
        >
          mdi-delete
        </v-icon>
        <v-dialog
            v-if="removeDialog"
            v-model="removeDialog"
            max-width="600px"
        >
          <v-card>
            <v-card-title>Möchten Sie dieses Fragebogenelement wirklich löschen?</v-card-title>
            <v-card-text>Nach dem Löschen ist das Wiederherstellen nicht mehr rückgänig zu machen! Die Datenbank wird
              nach löschen des Elements aktualisiert.
            </v-card-text>
            <v-btn class="ma-5" color="red" @click="removeElement()">Löschen</v-btn>
          </v-card>
        </v-dialog>
      </template>
    </v-treeview>

    <v-snackbar
        v-model="snackbar.on"
        timeout="4000"
    >
      <span v-if="snackbar.error" style="color: #FF5252">{{snackbar.text}}</span>
      <span v-else style="color: #4CAF50">{{snackbar.text}}</span>
    </v-snackbar>
  </v-card>
</template>

<script>
import {getAdminTree, initAdminPage} from "@/VarHandler";
import Option from "@/components/admin/Option";
import {api} from "@/api";

export default {
  name: "TopicsTree",
  components: {
    Option,
  },
  props: {
    tree: Array,
  },
  data: () => ({
    active: [],
    lastActiveId: 0,
    removeDialog: false,
    curCategoryQuestionIndices: {
      categoryIndex: 0,
      questionIndex: 0,
    },
    snackbar: {
      on: false,
      error: false,
      text: null,
    },
  }),
  computed: {
    breaking() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'lg';
    },
  },
  watch: {
    tree: {
      deep: true,
      handler() {
        console.log("change on tree");
      }
    }
  },
  methods: {
    opened(id) {
      this.active = id;
      if (id.length <= 0)
        this.$emit('selected', 0);
    },
    searchForIdObj(id) {
      for (let i = 0; i < this.tree.length; i++) {
        if (this.tree[i].id === id)
          return this.tree[i];
        for (let j = 0; j < this.tree[i].children.length; j++) {
          if (this.tree[i].children[j].id === id) {
            return this.tree[i].children[j];
          }
        }
      }
    },
    activated(id) {
      if (id.length <= 0) {
        this.$emit('selected', 0);
        return;
      }

      this.lastActiveId = id[0];

      let treeObj = this.searchForIdObj(id[0]);
      if (!treeObj) {
        this.curCategoryQuestionIndices.categoryIndex = 0;
        this.curCategoryQuestionIndices.questionIndex = 0;
        this.$emit('cur-category-question-indices', this.curCategoryQuestionIndices);
        return;
      }

      if(treeObj.topicIndex !== undefined)
        this.curCategoryQuestionIndices.categoryIndex = treeObj.topicIndex;
      this.curCategoryQuestionIndices.questionIndex = 0;

      if (treeObj.isSubTopic) {
        this.curCategoryQuestionIndices.questionIndex = treeObj.subTopicIndex;
        this.$emit('cur-category-question-indices', this.curCategoryQuestionIndices);
        this.$emit('selected', 2);
        return;
      }

      this.$emit('cur-category-question-indices', this.curCategoryQuestionIndices);
      this.$emit('selected', 1);
    },


    // ------- delete tree element code -------
    activateSnackbar(text, error) {
      this.snackbar.error = error;
      this.snackbar.text = text;
      this.snackbar.on = true;
    },
    async removeElement() {
      const lastElement = this.searchForIdObj(this.lastActiveId);
      let error = false;

      // evaluate if it is a category or question
      if (lastElement.isSubTopic) {
        // send DELETE /question/{id}
        const allGood = await api.delete(`api/question/${lastElement.dbId}`);
        if (allGood) {
          // delete question locally in tree
          await initAdminPage();
          // delete question in treeView
          const newTree = getAdminTree();
          this.tree = newTree;
          this.$emit('tree-change', newTree);
          // trigger snackbar success
          this.activateSnackbar(`Frage: ${lastElement.topic} entfernt.`, false);
        } else {
          // trigger snackbar error
          this.activateSnackbar(`Error: Frage ${lastElement.topic} konnte nicht gelöscht werden!`, true);
        }
        this.removeDialog = false;
        return;
      }
      // send DELETE /category/{name}
      console.log(lastElement);
      const allGoodCategory = await api.delete(`api/category/${lastElement.name}`);
      if (allGoodCategory) {
        // delete all connected questions
        for (const question of lastElement.children) {
          const allGoodQuestion = await api.delete(`api/question/${question.dbId}`);
          if (!allGoodQuestion)
            error = true;
        }

        // delete category locally in tree
        await initAdminPage();
        // delete category in treeView
        const newTree = getAdminTree();
        this.tree = newTree;
        this.$emit('tree-change', newTree);
        if (!allGoodCategory)
          error = true;

        if (error) {
          // trigger snackbar error
          this.activateSnackbar(`Kategorie: ${lastElement.name} konnte nicht gelöscht werden!.`, true);
        } else {
          // trigger snackbar success
          this.activateSnackbar(`Kategorie: ${lastElement.name} entfernt.`, false);
        }
      }

      this.removeDialog = false;
    },
  },
}
</script>

<style scoped>
.remove-icon:hover {
  color: red;
}
</style>