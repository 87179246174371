import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView';
import QuestionsView from "@/views/QuestionsView";
import EvaluationView from "@/views/EvaluationView";
import EditQuestionsView from '@/views/EditQuestionsView';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/questions',
    name: 'QuestionView',
    component: QuestionsView
  },
  {
    path: '/evaluation/:id',
    name: 'EvaluationView',
    component: EvaluationView
  },
  {
    path: '/edit',
    name: 'EditQuestionsView',
    component: EditQuestionsView 
  },
]

const router = new VueRouter({
  routes
})

export default router
