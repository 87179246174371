import { render, staticRenderFns } from "./EvaluationView.vue?vue&type=template&id=0da13bf8&scoped=true&%7B%7B=true&%24route.params.id=true&%7D%7D=true"
import script from "./EvaluationView.vue?vue&type=script&lang=js"
export * from "./EvaluationView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da13bf8",
  null
  
)

export default component.exports