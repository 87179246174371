import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{staticClass:"ma-0"},[_c(VSpacer),_c(VCard,{staticClass:"pa-10 text-center",attrs:{"width":"400px"}},[_c('h1',[_vm._v("Fragebogen starten")]),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/questions')}}},[_c('h2',[_vm._v("Start")]),_c(VIcon,{staticClass:"ml-2"},[_vm._v("mdi-arrow-right-drop-circle-outline")])],1)],1),_c(VSpacer)],1),_c('h2',{staticClass:"mb-10",staticStyle:{"text-align":"left","color":"black"}},[_vm._v("Fragebogen Übersicht:")]),_c(VDivider,{staticClass:"mt-5 mb-10"}),_c(VTimeline,{attrs:{"dense":_vm.screenWidth < 750}},_vm._l((_vm.categoryNames.length),function(i){return _c(VTimelineItem,{key:i,attrs:{"color":i < _vm.timelineItemColors.length ? _vm.timelineItemColors[i - 1].colorText : 'primary',"large":""},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_c('span')]},proxy:true}],null,true)},[_c(VCard,{staticClass:"rounded-b-lg"},[_c(VRow,{staticClass:"ma-0"},[_c(VTreeview,{attrs:{"items":_vm.getQuestions(_vm.categoryNames[i - 1], _vm.categories[i - 1].questions),"open-on-click":""},on:{"update:open":function($event){return _vm.onTreeViewActive(i - 1)}}}),(_vm.screenWidth >= 750 && _vm.treeViewOpenStates[i - 1])?_c(VImg,{staticClass:"ma-5",attrs:{"src":_vm.categories[i - 1].img ? _vm.categories[i - 1].img : 'https://picsum.photos/510/300?randomÄ',"aspect-ratio":"2"}}):_vm._e()],1),(_vm.screenWidth < 750 && _vm.treeViewOpenStates[i - 1])?_c(VImg,{staticClass:"ma-5",attrs:{"src":_vm.categories[i - 1].img ? _vm.categories[i - 1].img : 'https://picsum.photos/510/300?randomÄ',"aspect-ratio":"2"}}):_vm._e()],1)],1)}),1),_c(VDivider,{staticClass:"mt-10 mb-8"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }