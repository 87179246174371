<template>
    <v-container>
      <v-card color="darkblue pt-2 pb-2">
        <v-row
          no-gutters
          >
          <v-col
              :cols="breaking?12:2"
              class="d-flex justify-center align-center"
          >
            <v-img
              contain
              max-height="40px"
              src="../assets/global-ct-logo-white.svg"
              class="ma-2 chMouse"
              v-on:click="gotoHome($event)"
            ></v-img>
          </v-col>
          <v-col
              :cols="breaking?12:8"
              >
                <h1 class="white--text ma-1 font-weight-bold">
                Reifegrad Prüfer
                </h1>
          </v-col>
          <v-col
            class="d-flex justify-center align-center"
            :cols="breaking?12:2"
            >
          </v-col>
        </v-row>
      </v-card>
      <v-divider></v-divider>
  </v-container>
</template>

<script>
export default {
  name: "Header",
  data:() => ({
    
  }),
  methods: {
    gotoHome(ev){
      this.$router.replace('/').then(() => {}, (e)=>{});
    }
  },
  computed: {
      breaking(){
          return this.$vuetify.breakpoint.name == "xs";
      }
  },
}
</script>

<style scoped>
.chMouse:hover {
  cursor: pointer;
}
h1 {
  color: #eefbfb;
  text-align: center;
}
</style>