<template>
  <v-container class="box">
    <v-progress-circular
        v-if="!errorMessage"
        :size="75"
        color="neutralgray"
        indeterminate
    ></v-progress-circular>
    <h1 class="error--text" v-if="errorMessage">
      {{errorMessage}}
    </h1>
  </v-container>
</template>

<script>
export default {
  name: "Loading",
  props: {
    errorMessage: String,
  }
}
</script>

<style>
  .box {
    height: 600pt;
    text-align: center;
    vertical-align: middle;
    line-height: 600pt;
  }
</style>