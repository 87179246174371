<template>
  <v-card class="pa-3" outlined>

    <!-- reset button -->
    <v-row class="ma-5" style="justify-content: center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="orange"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="resetDialog = true"
              width="100px"
              height="75px"
          >
            <v-icon class="mr-1">mdi-cached</v-icon>
          </v-btn>
        </template>
        <span>Setzt lokale Änderungen zurück.</span>
      </v-tooltip>
      <v-dialog
          v-model="resetDialog"
          width="500px"
      >
        <v-card class="pa-5">
          <v-row class="ma-0 pa-0">
            <v-col cols="11" class="pa-0">
              <v-card-title>
                Zurücksetzen
              </v-card-title>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-icon @click="resetDialog = false">mdi-close</v-icon>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-card-text>
                Wollen Sie ihre lokalen Änderungen (Texte und hinzugefügte/entfernte Optionen) wirklich zurücksetzen?
                Alle Änderungen gehen verlohren und werden nicht an den Server geschickt.
              </v-card-text>
            </v-col>
            <v-col class="pa-0">
              <v-btn
                  class="ml-3 mt-5"
                  color="orange"
                  @click="reset()"
              >
                Zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- save button -->
    <v-row class="ma-5" style="justify-content: center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="save()"
              width="100px"
              height="75px"
          >
            <v-icon class="mr-1">mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>
          Veränderte Texte wie auch entfernte/hinzugefügte Optionen werden an den Server geschickt
          und für jeden im Fragebogen aktualisiert.
        </span>
      </v-tooltip>
    </v-row>

    <v-divider></v-divider>

    <!-- add category section -->
    <v-row class="ma-5" style="justify-content: center">
      <span>Kategorie</span>
    </v-row>
    <v-row class="ma-5" style="justify-content: center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="green"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="activateAddDialog(true)"
              width="100px"
              height="50px"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>
          Kategorie hinzufügen
        </span>
      </v-tooltip>
    </v-row>

    <v-divider></v-divider>

    <!-- add category section -->
    <v-row class="ma-5" style="justify-content: center">
      <span>Frage</span>
    </v-row>
    <v-row class="ma-5" style="justify-content: center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="green"
              outlined
              v-bind="attrs"
              v-on="on"
              @click="activateAddDialog(false)"
              width="100px"
              height="50px"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>
          Frage hinzufügen
        </span>
      </v-tooltip>
    </v-row>

    <!-- add category dialog -->
    <v-dialog
        v-model="addCategoryDialog"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title>
          Möchten Sie eine neue Kategorie hinzufügen?
        </v-card-title>
        <v-card-text>
          Bitte editieren Sie ihre neue Kategorie bevor sie dem Fragebogen (Server) hinzugefügt wird.
        </v-card-text>
        <!-- category data -->
        <v-form ref="addCategoryForm" class="ml-6 mr-6">
          <v-text-field
              v-model="newCategoryOrder"
              label="Reihenfolge"
              :rules="rules.number"
          ></v-text-field>
          <v-text-field
              v-model="newCategoryName"
              label="Bezeichnung"
              :rules="rules.required"
          ></v-text-field>
        </v-form>
        <v-row class="ma-0 pa-0">
          <v-btn class="ma-6" color="primary" @click="addCategoryDialog = false">Abbruch</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="ma-6" color="green" @click="addCategory()">Hinzufügen</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="addQuestionDialog"
        max-width="600px"
        persistent
    >
      <v-card>
        <v-card-title>
          Möchten Sie eine neue Frage hinzufügen?
        </v-card-title>
        <v-card-text>
          Bitte editieren Sie ihre neue Frage bevor sie dem Fragebogen (Server) hinzugefügt wird.
        </v-card-text>

        <v-form ref="addQuestionForm" class="ml-6 mr-6">
          <!-- question data -->
          <v-text-field
              v-model="newQuestionObj.order"
              label="Reihenfolge"
              :rules="rules.number"
          ></v-text-field>
          <v-select
              v-model="newQuestionObj.categoryName"
              :items="newQuestionObj.categories"
              label="Kategorie"
              :rules="rules.required"
          ></v-select>
          <v-text-field
              v-model="newQuestionObj.topic"
              label="Thema"
              :rules="rules.required"
          ></v-text-field>
          <v-text-field
              v-model="newQuestionObj.introductionText"
              label="Intro Text"
              :rules="rules.required"
          ></v-text-field>
          <v-text-field
              v-model="newQuestionObj.question"
              label="Frage"
              :rules="rules.required"
          ></v-text-field>
          <div id="optionPlaceholder" v-for="(option, index) in newQuestionObj.options">
            <Option
                v-if="option.isVisible"
                :text="option.text"
                :value="Number(option.value)"
                :index="index"
                @text="setOptionText"
                @value="setOptionValue"
                @close="deleteOption"
            ></Option>
          </div>

          <!-- add new option buttons -->
          <v-row v-if="!showAddOptionOverlay" class="ma-0">
            <v-spacer></v-spacer>
            <span class="mr-3">Neue Auswahloption hinzufügen</span>
            <v-icon class="add-icon" @click="activateNewOptionOverlay()">mdi-plus</v-icon>
          </v-row>

          <!-- add new option overlay -->
          <v-form v-else ref="newOptionForm">
            <v-card
                class="ma-0 pl-3 pr-3"
                outlined
            >
              <v-row class="mb-3 mt-3 ml-0 mr-0">
                <v-col cols="12" class="pa-0 mr-5">
                  <v-text-field
                      v-model="newQuestionObj.options[newQuestionObj.options.length - 1].text"
                      label="Option Text"
                      :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="pa-0">
                  <v-text-field
                      v-if="showAddOptionOverlay"
                      v-model="newQuestionObj.options[newQuestionObj.options.length - 1].value"
                      label="Wert"
                      type="number"
                      :rules="rules.number"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="pt-5 pl-5">
                  <v-row class="ma-0">
                    <v-icon class="remove-icon" color="red" @click="deactivateNewOptionTab()">mdi-minus</v-icon>
                    <v-icon class="add-icon" color="green" @click="addOption()">mdi-plus</v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-form>

        <v-row class="ma-0 pa-0">
          <v-btn class="ma-6" color="primary" @click="quitQuestionDialog()">Abbruch</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="ma-6" color="green" @click="addQuestion()">Hinzufügen</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar.on"
        timeout="4000"
    >
      <span v-if="snackbar.error" style="color: #FF5252">{{ snackbar.text }}</span>
      <span v-else style="color: #4CAF50">{{ snackbar.text }}</span>
    </v-snackbar>
  </v-card>
</template>

<script>
import {initAdminPage, getAdminTree} from "@/VarHandler";
import Option from "@/components/admin/Option";
import {api} from "@/api";

export default {
  name: "SaveEditSection",
  components: {
    Option,
  },
  data: () => ({
    snackbar: {
      on: false,
      text: null,
      error: false,
    },
    resetDialog: false,
    addCategoryDialog: false,
    addQuestionDialog: false,
    newCategoryName: null,
    newCategoryOrder: null,
    newQuestionObj: {
      order: null,
      categories: [],
      topic: null,
      introductionText: null,
      question: null,
      options: [],
      categoryName: null,
    },
    showAddOptionOverlay: false,
    rules: {
      required: [val => (val || '').length > 0 || 'Muss gefüllt werden!'],
      number: [val => (val && !isNaN(val) && Number(val) > 0) || 'Ungültige Zahl']
    },
  }),
  computed: {
    breaking() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'lg';
    },
  },
  methods: {
    async reset() {
      await initAdminPage().then(() => {
        location.reload();
        console.log('reset');
        this.resetDialog = false;
      });
    },
    /*
      * This function handles all textural changes for categories and questions.
      * Types of changes are:
      * Removed/Add/Switch position: instant change request to backend (will not be handled here)
      * Changed Text: Look for every category and question (will be handled here)
      */
    async save() {
      const tree = getAdminTree();

      // get all original categories
      const originalCategories = await api.get('api/category');
      if (!originalCategories) {
        this.activateSnackbar('Kategorien konnten nicht abgefragt werden!', true);
        return;
      }
      let error = false;

      // loop avery category
      for (const [categoryIdx, category] of tree.entries()) {
        if (tree[categoryIdx].changed) {
          const allGoodCategories = await api.put(`/api/category/${originalCategories[categoryIdx].name}`, {
            name: category.name,
            order: Number(category.order),
            img: category.img,
          });

          if (!allGoodCategories) {
            console.log('Something broke!');
            error = true;
          }
        }

        // get all original questions for category
        const originalQuestions = await api.get(`api/question/findByCategory/${originalCategories[categoryIdx].name}`);
        if (!originalQuestions) {
          this.activateSnackbar(`Fragen zu Kategorie ${originalCategories[categoryIdx].name} konnten nicht abgefragt werden!`, true);
          return;
        }

        // loop every question
        for (const [questionIdx, question] of category.children.entries()) {
          if (tree[categoryIdx].children[questionIdx].changed) {
            const allGoodQuestion = await api.put(`/api/question/${originalQuestions[questionIdx].id}`, {
              topic: question.name,
              introductionText: question.introductionText,
              question: question.question,
              options: question.options.map(op => {
                op.value = Number(op.value);
                return op;
              }),
              category: category.name,
              order: Number(question.order),
            });

            if (!allGoodQuestion) {
              console.log('Something broke!');
              error = true;
            }
          }
        }
      }

      // trigger snackbar for saved status feedback
      this.activateSnackbar(error ? 'Ein Fehler ist aufgetreten!' : 'Speicherung erfolgreich.', error);
    },

    // ----- add element code -----

    activateAddDialog(isCategory) {
      this.newCategoryName = null;
      this.newQuestionObj = {
        order: null,
        categories: getAdminTree().map(category => category.name),
        topic: null,
        introductionText: null,
        question: null,
        options: [],
        categoryName: null,
      };
      this.addCategoryDialog = isCategory;
      this.addQuestionDialog = !isCategory;

      // reset forms
      if(this.$refs.addCategoryForm)
        this.$refs.addCategoryForm.reset();
      if(this.$refs.addQuestionForm)
        this.$refs.addQuestionForm.reset();
    },

    activateNewOptionOverlay() {
      this.newQuestionObj.options.push({
        text: null,
        value: null,
        isVisible: false,
      });
      this.showAddOptionOverlay = true;
    },
    deactivateNewOptionTab() {
      this.newQuestionObj.options.pop();
      this.showAddOptionOverlay = false;
    },
    deleteOption(index) {
      this.newQuestionObj.options.splice(index, 1);
    },
    addOption() {
      // check for rules
      if (this.$refs.newOptionForm.validate()) {
        this.newQuestionObj.options[this.newQuestionObj.options.length - 1].isVisible = true;
        this.showAddOptionOverlay = false;
      }
    },
    setOptionText(text, index) {
      this.newQuestionObj.options[index].text = text;
    },
    setOptionValue(value, index) {
      this.newQuestionObj.options[index].value = Number(value);
    },
    activateSnackbar(text, error) {
      this.snackbar.error = error;
      this.snackbar.text = text;
      this.snackbar.on = true;
    },
    quitQuestionDialog() {
      this.showAddOptionOverlay = false;
      this.newQuestionObj.options = [];
      this.addQuestionDialog = false;
    },
    async addCategory() {
      if (this.$refs.addCategoryForm.validate()) {
        // send POST /category
        const allGood = await api.post('api/category', {
          name: this.newCategoryName,
          order: Number(this.newCategoryOrder),
          img: null,
        });

        if (allGood) {
          // refresh
          await initAdminPage();
          this.$emit('tree-change', getAdminTree());

          // trigger snackbar success
          this.activateSnackbar(`Kategorie: ${this.newCategoryName} hinzugefügt.`, false);
        } else {
          // trigger snackbar error
          this.activateSnackbar(`Error: Kategorie ${this.newCategoryName} konnte nicht hinzugefügt werden!`, true);
        }

        this.addCategoryDialog = false;
      }
    },
    async addQuestion() {
      console.log(this.$refs.addQuestionForm);
      if (this.$refs.addQuestionForm.validate()) {
        // send POST /question
        const allGood = await api.post('api/question', {
          topic: this.newQuestionObj.topic,
          introductionText: this.newQuestionObj.introductionText,
          question: this.newQuestionObj.question,
          options: this.newQuestionObj.options.map(option => {
            option.value = Number(option.value);
            return option;
          }),
          category: this.newQuestionObj.categoryName,
          order: Number(this.newQuestionObj.order),
        });

        if (allGood) {
          // refresh tree
          await initAdminPage();
          this.$emit('tree-change', getAdminTree());

          // trigger snackbar success
          this.activateSnackbar(`Frage: ${this.newQuestionObj.topic} hinzugefügt.`, false);
        } else {
          // trigger snackbar error
          this.activateSnackbar(`Error: Frage ${this.newQuestionObj.topic} konnte nicht hinzugefügt werden!`, true);
        }

        this.addQuestionDialog = false;
      }
    },
  },
}
</script>

<style scoped>

</style>