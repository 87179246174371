<template>
  <v-container>
    <v-footer
      color="darkblue"
      width="100%"
      >
      <v-card-text class="white--text ma-1 font-weight-light">
        <p class="mb-2 font-weight-bold">2022 - <a href="https://globalct.com/">globalCT</a></p>
        <p>
          globalCT ist eine Unternehmensberatung mit Hauptsitz in Hannover. In den Geschäftsbereichen global CT Consulting & global CT Digital begleiten wir Unternehmen auf ihrem digitalen Transformationsweg.
        </p>
      </v-card-text>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({

  })
}
</script>

<style scoped>
  p {
  color: #eefbfb;
  text-align: center;
    margin: 0 auto;
    width: 85%;
  font-weight: lighter;
}
  @media (max-width: 750px) {
    p {
      width: 100%;
    }
  }
a {
  font-weight: bold;
}
</style>